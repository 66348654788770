<template>
  <div>
    <video-bg
      v-show="error"
      :sources="[`${publicPath}404.mp4`]"
    >
      <v-container
        class="d-flex flex-column fill-height align-center justify-center"
        fluid
      >
        <h3
          class="text-h3 font-weight-black white--text mb-8"
        >
          {{ error }}
        </h3>
        <v-btn
          x-large
          @click="logout"
        >
          Logout
        </v-btn>
      </v-container>
    </video-bg>
  </div>
</template>

<script>
export default {
  name: 'LoginCallback',
  data: () => ({
    publicPath: process.env.BASE_URL,
    error: null
  }),
  methods: {
    async logout () {
      await this.$auth.signOut()
    }
  },
  async beforeMount () {
    try {
      await this.$auth.handleLoginRedirect()
    } catch (error) {
      if (this.$auth.isInteractionRequiredError(error)) {
        const { onAuthResume, onAuthRequired } = this.$auth.options
        const callbackFn = onAuthResume || onAuthRequired
        if (callbackFn) {
          callbackFn(this.$auth)
          return
        }
      }
      this.error = error.toString()
    }
  }
}
</script>
